import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const KosciolRzymskoKatolickiCeglowPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Kościół Rzymsko-Katolicki w Cegłowie" />
      <ReturnToPrevPage to={'/gminy/ceglow'}>
        Materiały dotyczące gminy Cegłów
      </ReturnToPrevPage>
      <Header1>Kościół Rzymsko-Katolicki w Cegłowie</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <VideoPlayer videoId={895165967} />
        <p>
          Cegłów ( pierwotna nazwa do XVI w. -Cebrowo). Od XIV w. stanowi
          uposażenie klasztoru augustianów w Warszawie, później założonego przy
          nim w 1444 roku szpitala Św. Ducha. Pierwsza świątynia drewniana
          powstała w latach 1441-1444. Do 1534 roku Cegłów był filialnym
          kościołem parafii Kiczki. Fundatorką tej pierwszej świątyni była Anna
          Mazowiecka z Holszańskich – Jagiellonka, żona Władysława. Do tego
          dzieła przyczynił się też biskup poznański Andrzej z Bnina. W roku
          1547 biskup poznański Benedykt Łubieński przekazał Cegłów Kapitule
          Warszawskiej. W roku 1603 założono w Cegłowie szkołę parafialną.
        </p>
        <p>
          Obecny murowany kościół późnogotycki został wzniesiony w połowie XVI
          wieku. W 1621 roku Zygmunt III Waza nadał Cegłowowi prawa miejskie w
          oparciu o prawo magdeburskie, wraz z herbem (stylizowany snop i głowa
          Jana Chrzciciela). 21 grudnia 1629 roku biskup poznański Maciej
          Łubieński dokonał konsekracji kościoła i z tego czasu pochodzą:
          wschodni szczyt prezbiterium, sklepienie zakrystii, oraz portal. W
          1862 roku, podczas remontu, sklepienie kościoła zostało podparte w
          części nawowej, dwoma rzędami modrzewiowych kolumn. Prawa miejskie
          Cegłów utracił w 1869 roku.
        </p>
        <p>
          Od 1629 roku w cegłowskiej świątyni znajduje się tryptyk zwany
          „Tryptykiem Cegłowskim”. Wykonany z inicjatywy biskupa poznańskiego
          Jana Lubrańskiego około roku 1510 przez mistrza Lazarusa z Warszawy –
          ucznia Wita Stwosza, dla Kolegiaty Warszawskiej, ale został przekazany
          przez Kapitułę dla kościoła w Cegłowie. Tryptyk cegłowski jest
          wybitnym dziełem rzeźbiarskim plastyki późnogotyckiej z racji figur i
          płaskorzeźbionych kwater, oprawionych w barokowe retabulum. W
          centralnej części znajduje się figura Madonny z Dzieciątkiem w
          koronie, z lewej strony Jan Chrzciciel – patron Parafii, z prawej św.
          Stanisław biskup męczennik – patron Polski. Otaczające kwatery
          przedstawiają, po stronie lewej od góry: św. Barbarę (306 r.) więzioną
          w wieży przez ojca, poniżej św. Dorota (303 r. ) męczennica z Cezarei
          Kapadockiej, po stronie prawej od góry: św. Katarzyna ( 312 r.)
          skazana na miażdżenie kołem, a ostatecznie ścięta mieczem, poniżej św.
          Małgorzata ( 307 r.) ścięta w Antiochii za wiarę. W skład ołtarza
          wchodziły też malowane skrzydła przedstawiające legendy o św. Janie
          Chrzcicielu. Zostały przekazane do Warszawy w 1913 do Muzeum
          Diecezjalnego i spłonęły podczas działań wojennych 1944 roku.
        </p>
        <p>
          W nastawie ołtarza rzeźby: św. Anny Samotrzeć, św. Piotra i św. Pawła.
          Zwieńczenie stanowi rzeźba Boga Ojca w otoczeniu aniołów. Tabernakulum
          barokowe z XVIII wieku. Na granicy nawy i prezbiterium znajduje się
          belka tęczowa z rzeźbą Chrystusa Ukrzyżowanego z końca XVI w. św. Jana
          i św. Marii Magdaleny. Ołtarze boczne z XIX wieku stylizowane na
          barok. Ambona z wieku XVIII. Dzwonnica wolnostojąca z wieku XVIII.
          Wokół kościoła trzy lipy drobnolistne – pomniki przyrody.
        </p>
        <p>
          Prace konserwatorskie w Cegłowskim kościele przyniosły wiele odkryć, a
          wśród nich renesansowy portal w prezbiterium oraz renesansowny portal
          na zewnątrz wraz z zachowanymi drzwiami. Te drugie można podziwiać z
          zewnątrz.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/ceglow/kosciol-w-ceglowie/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: { relativePath: { regex: "/ceglow/kosciol-w-ceglowie/" } }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default KosciolRzymskoKatolickiCeglowPage
